.white-form {
    outline: 0.5px solid $secondary;
    font-weight: 300;
    font-size: 15px;
    &::autocomplete {
      background-color: transparent !important;
      color: $secondary;
    }
    &:focus,
    &:focus-visible,
    &:hover,
    &:not(:active)  {
      color: $secondary;
      outline: 0.5px solid $secondary;
    }
}

  .mute-form {
    outline: 1px solid rgba($secondary, $alpha: .6);
    font-weight: 300;
    font-size: 15px;
    &::placeholder {
      color: rgba($secondary, $alpha: .6)
    }
    &::autocomplete {
      background-color: transparent !important;
      color: $secondary;
    }
    &:focus {
      color: $secondary; 
      outline: 1px solid rgba($secondary, $alpha: .6);
    }
  }

  .cyan-form {
    outline: 0.5px solid $primary;
    font-weight: 300;
    font-size: 15px;
    &::autocomplete {
      color: $secondary;
    }
    &:focus {
      color: $secondary; 
      outline: 0.5px solid $primary;
    }
  }

  .input-group {
    background-color: transparent !important;
    border-radius: 30px;
    height: 32px;
    font-size: 15px;
    vertical-align: middle;
    &::autocomplete {
        background-color: transparent !important;
    }
  }

  .form-control {
    background-color: transparent !important;
    height: 35px; 
    border-radius: 30px;
    color-scheme: dark;
    &::placeholder {
      color: rgba($secondary, $alpha: .4); 
    } 
    &:focus,
    &:focus-visible,
    &:hover,
    &:not(:active) {
      background-color: transparent !important;;
      border-color: transparent;
      box-shadow: none;
      color: $secondary
    } &::before {
      background-color: transparent !important
    }
  }

  .select {
    font-weight: 300;
    font-size: 12px;
    padding-left: 8px;
    caret-color: white;
    border-right-width: 12px;
    border-color: transparent;
    height: 35px;
    color: $secondary; 
    &:focus,
    &:not(:active) {
      border-color: transparent;
      border-right-width: 12px;
    }
    &::placeholder {
     color: $secondary; 
    }
  }

  .input-bg {
    background-color: transparent;
    border-color: transparent;
    color: $secondary
  }
  
  .form-container {
    background-color: $bg-dark;
    border-radius: 30px;
    padding: 50px;
    width: 100%;
    display: flex;
  }
  
  .provider-container {
    background-color: $bg-dark;
    border-radius: 30px;
    padding: 50px;
    margin: 0 10%;
  }

