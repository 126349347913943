.client-data-wrapper {
  font-size: .9rem;
  background-color: $bg-dark;
}

.more-info {
  font-size: xx-large;
  margin-right: 10px;
  padding: 0;
}

.client-table-container {
  overflow: scroll;
  flex: auto;
  padding-right: 2%;
  margin-left: 5%;
}

.client-table {
  border-collapse: separate;
  border-spacing: 0 25px;
}

.client-table tbody tr:hover {
  color: white;
  opacity: 0.75;
}

.table>tbody>tr>td {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: $bg-dark;
  width: 200px;
}

.client-table td:first-child {
  border-radius: 30px 0 0 30px;
}

.provider-clients-client-id {
  line-height: .8;
}

.badge {
  width: 30px;
}

.outlined-badge {
  background-color: transparent;
  color: $secondary;
  outline: 1px solid $primary;
  width: 100%;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 300;
  padding-top: 5px;
  padding-bottom: 5px;
}

.pagination {
  background-color: transparent;
  color: white; 
  border: 0.5px solid white;
  border-radius: 30px;
}

.pagination .page-item.active .page-link {
  color: white;
  margin-right: 15px;
  margin-left: 15px;
  border: 0.5px;
}

.pagination .page-item.active:hover .page-link {
  font-weight: 700;
  color: white;
}

.pagination .page-item .page-link {
  background-color: transparent;
  border: 0;
  color: white;
}

.pagination .page-item.inactive .page-link {
  font-weight: normal;
  color: white;
  opacity: 0.8;
}
