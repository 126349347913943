.caret-down {
  bottom: px-to-rem(-5px);
  color: $secondary;
}

.caret-up {
  bottom: px-to-rem(11px);
  color: $secondary;
}

.caret-up, .caret-down {
  height: px-to-rem(16px);
  color: $secondary;
}

.category-sort {
  color: $text-muted;
  &:hover {
    color: $secondary;
  }
}