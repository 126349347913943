.login-form-container {
  height: 100vh;
  background-color: $bg-dark;
  color: white;
  padding-top: 6%;
}

.login-bg {
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  opacity: 0.03;
}

.home-logo {
  max-width: px-to-rem(100px);
}

.help-btn {
  width: 120px;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: 1030;
}

.login-fields {
  width: 330px
}

.form-label{
  font-size: 15px;
}

.loader-text{
  font-size: 12px;
}