.create-alert {
  margin-bottom: px-to-rem(100px);
}

.create-map-container {
  width: 100%;
  height: px-to-rem(250px);
}

.create-toast {
  top: px-to-rem(20px);
  width: px-to-rem(300px);
  left: 0;
  right: 0;
  margin: 0 auto;
}

.submit-button {
  width: px-to-rem(200px);
  margin-bottom: px-to-rem(70px);
  font-weight: 300;
}
