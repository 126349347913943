/* Override bootstrap variables */
@import './custom';
/* Import scss files as their are needed */
@import './functions';
@import './utilities';
@import './components/login_page';
@import './components/provider_clients.scss';
@import './components/provider_clients_card.scss';
@import './components/provider/client_form.scss';
@import "./components/provider/comparison_cards.scss";
@import "./components/provider/client_sort.scss";
@import "./bullletPoint.scss";
@import "./components/charts.scss";
@import "./components/forms.scss";
@import "./components/navbar.scss";

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

body > #root > div {
  height: 100vh;
  overflow-x: hidden;
}