.dot {
  height: px-to-rem(20px);
  width: px-to-rem(20px);
  background-color: $green;
  background-color: $green;
  border-radius: 50%;
  display: inline-block;
}

.status-dot {
  height: px-to-rem(10px);
  width: px-to-rem(10px);
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.status-dot.active {
  background-color: green; /* Change color for active status */
}

.status-dot.inactive {
  background-color: red; /* Change color for inactive status */
}

.cog-dropdown {
  color: $dark-cyan;

  &::after {
    display: none;
  }
  &::before {
    content: none;
  }
}


.table>tbody>tr>td,
.table>tbody>tr>th,
.table>thead>tr>th {
  border: none;
}

.text-sm {
  font-size: 12px;
}

.text-lg {
  font-size: 20px;
}

.side-menu {
  background-color: $bg-light;
  margin-right: auto;
  padding: 1.5%
}

.btn-primary {
  background-color: $primary;
  border-color: none;
  border-width: 0px;
  border-radius: 30px;
  color: #143442;
  font-weight: 500;
  padding-right: 10%;
  padding-left: 10%;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: #49c6be;
  border-color: none;
  border-width: 0px;
  border-radius: 30px;
  color: #143442;
  font-weight: 500;
  padding-right: 10%;
  padding-left: 10%;
}

.btn-outline {
  background-color: transparent;
  border-color: $dark-cyan;
  border-width: 0.5px;
  border-radius: 30px;
  color: $primary;
  font-weight: 400;
  padding-right: 10%;
  padding-left: 10%;
  height: auto;
  width: auto;
  &:hover,
  &:active,
  &:focus,
  &:not(:disabled):not(:disabled):active {
    background-color: $bg-light;
    border-color: $primary;
    border-width: 0.5px;
    color: $primary;
  }
}


.plain-background {
  background-color: $bg-dark;
}

.gradient-background {
  @include gradient-y($bg-light, $bg-dark);
}

.dark-gradient-bg {
  @include gradient-y($bg-card-dark, $bg-card-light);
  border: 0;
  border-radius: 30px;
}

.total-projects {
  background-color: #252739;
  border-radius: 30px;
  height: 80px;
  width: 100%;
}

.bg-darks {
  background-color: $bg-dark;
}

.card-summary {
  border-radius: 30px;
  padding: 15px;
  color: $secondary;
  border: none;
  height: 165px;
  font-size: 13px;
}

.card-body {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.close {
  color: $secondary;
  text-shadow: none;
  font-weight: 300;
}

.sidebar-column{
  flex: 0 0 20%;
  max-width: 20%;
  padding: 5% 10%;
  background-color: $bg-dark;
  height: 100%;
}

.content-column{
  flex: 0 0 80%;
  width: 80%;
  padding-right: 0;
}

.client-dashboard{
    margin-left: 5%;
    width: 90%;
}

.warning-alert {
  background-color: $bg-light;
  color: $secondary;
  font-size: 13px;
}

.custom-tooltip{
  background-color: #0d122e;
  color: $secondary;
  outline: 1px solid rgba(#454a61, $alpha: 1);
  border-color: #454a61;
  border-radius: 10px;
  box-shadow: 0 0 50px rgba(0,0,0,.25);
  padding: 5px;
}

.modal-content{
  background-color: #0d122e;
  border-color: #151721;
  outline: 1px solid rgba(#454a61, $alpha: 1);
  box-shadow: 0 0 50px rgba(0,0,0,.25);
  color: $secondary;
  border-radius: 15px;
}

.text-blue{
  color: $chart-blue;
}
.text-cyan{
  color: $chart-cyan;
}

span.MuiSlider-markLabel {
  color: $text-muted;
  font-size: 12px
}
.MuiSlider-rail{
  background-color: #143442 !important
}
.MuiSlider-track {
  background-color: $dark-cyan !important
}
.MuiSlider-thumb {
  background-color: $dark-cyan !important
}
.MuiSlider-mark, .MuiSlider-markActive{
  color: $bg-light
} 

.PrivateValueLabel-circle-4 {
  width: 32px;
  height: 32px;
  display: flex;
  transform: rotate(-45deg);
  align-items: center;
  border-radius: 50% 50% 50% 0;
  justify-content: center;
  background-color: #143442 !important;
}

.PrivateValueLabel-label {
  color: $dark-cyan;
  transform: rotate(45deg); 
}

.select.white-form option {
  color: $bg-dark; 
}

.nav-tabs .nav-link.active {
  background-color: $bg-light;
  font-weight: 600;
}
