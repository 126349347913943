.card-info-button {
  width: px-to-rem(70px);
  height: px-to-rem(34px);
}

.card-info-client {
  font-size: px-to-rem(14px);
}

.card-title-client {
  font-size: px-to-rem(12px);
}

.card-column-group {
  display: grid;
  justify-items: center;
  grid-template-columns: auto;

  @media (min-width: 720px) {
    grid-template-columns: auto auto;
  }

  @media (min-width: 1070px) {
    grid-template-columns: auto auto auto;
  }

  @media (min-width: 1368px) {
    grid-template-columns: auto auto auto auto;
  }
}

@media (min-width: 1200px){
.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: none;
}}

@media (min-width: 992px){
.container, .container-sm, .container-md, .container-lg {
  max-width: none;
}}

@media (min-width: 768px){
.container, .container-sm, .container-md {
  max-width: none;
}}

@media (min-width: 576px){
.container, .container-sm {
  max-width: none;
}}

@media (min-width: 1200px){
.container {
  max-width: none;
}}

@media (min-width: 992px){
.container {
  max-width: none;
}}

@media (min-width: 768px){
.container {
  max-width: none;
}}

@media (min-width: 576px){
.container {
  max-width: none;
}}