
.navbar {
    background-color: $bg-dark;
    border-radius: 0px 0px 30px 30px;
    height: 100px;
    margin-left: 6%;
    padding-left: 15px;
    padding: 1.5%;
    color: $secondary
  }
  
  .navbar .navbar-nav .nav-link {
    color: $secondary;
  }
  
  .dropdown-menu {
    background-color: #0a0c24;
  }
  
  .dropdown-item {
    color: $secondary;
  }
  
  .dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus {
    color: rgba(255, 255, 255, 0.723);
    background-color: #07091b;
  }

  .sidebar {
    height: 100%;
    padding: 10%;
    background-color: $bg-dark;
  }

  .no-sidebar {
    width: 1%;
  }

