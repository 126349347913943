// Following react-bootstrap docs https://react-bootstrap.github.io/getting-started/introduction

/* make the customizations for bootstrap variables */
$primary: #4FD7CF;
$secondary: #eff1f4;
$text-muted: #848597;
$text-light: #93d6d2;

$dark: #04071E;

$bg-light: #13303f;
$bg-dark: #04071E;
$bg-card-dark: #04071E;
$bg-card-light:#050928;

$green: #0DA11C;
$red: #BA1313;
$yellow: #F6C25B;

$chart-yellow: #F6C25B;
$chart-cyan: #4FD7CF;
$chart-blue: #4FA4D7;

$dark-yellow: #AD8A49;
$dark-cyan: #38999A;
$dark-blue: #38759F;

$body-bg-dark: #04071E;

$border-radius: .5rem;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'text-muted': $text-muted,
  'text-light': $text-light,

  'bg-light': $bg-light,
  'bg-dark': $bg-dark,
  'bg-card-light': $bg-card-light,
  'bg-card-dark': $bg-card-light,
  'dark': $dark,
  'yellow': $yellow,
  'red': $red,
  'green': $green,

  'chart-yellow': $chart-yellow,
  'chart-cyan': $chart-cyan,
  'chart-blue': $chart-blue,

  'dark-yellow': $dark-yellow,
  'dark-cyan': $dark-cyan,
  'dark-blue': $dark-blue
);

// Cards
$card-border-radius: .9rem;

$overflow: (
  "overflow": (
    property: overflow,
    values: auto hidden visible scroll,
  ),
),